import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import debounce from 'lodash.debounce';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  useMediaQuery,
  styled,
  useTheme,
} from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon, Menu as MenuIcon } from '@mui/icons-material';

import searchService from 'services/search';

import { NAVBAR_MENUS, NAV_MENUS } from 'constants/index';
import useTopValue from 'hooks/useTopValue';
import { openLink } from 'utils/openLink';

import { MenuButton, ToolTip, GMILogo } from 'components/StyledComponents';
import SideNavigation from './SideNavigation';
import AccountSettingsSidebar from './AccountSettingsSidebar';
import WalletSidebar from './WalletSidebar/index';
import SubMenu from './SubMenu';
import { DarkIcon, LightIcon } from 'components/ImageComponent';
import SearchResultContainer from './SearchResultContainer';
import AddressButton from './AddressButton';

/********************  Styled Components  ********************/
const Root = styled('div')(({ top }) => ({
  height: 60,
  width: '100%',
  zIndex: 1300,
  position: 'fixed',
  top: top,

  '@media (max-width: 1439px)': {
    transform: 'scale(0.8)',
    transformOrigin: '0 0',
    width: 'calc(100% / 0.8)',
  },
  '@media (max-width: 1279px)': {
    transform: 'none',
    width: '100%',
  },
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  padding: theme.spacing(0, 5.5),
  backgroundColor: theme.palette.background.secondary,
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.border.separator}`,

  '& > div, & > a': {
    padding: theme.spacing(0, 1.6),
  },
  '@media (max-width: 1439px)': {
    padding: theme.spacing(0, 4),
  },
  '@media (max-width: 1279px)': {
    padding: theme.spacing(0, 3),
  },
}));

const SearchFieldContainer = styled(Box)(({ theme, fixed, top }) => ({
  flex: 1,
  position: fixed ? 'fixed' : 'relative',
  padding: '0 !important',
  marginLeft: theme.spacing(1),
  top: fixed ? top + 60 : 'auto',
  width: '100%',
  borderBottom: fixed && `1px solid ${theme.palette.border.separator}`,
  zIndex: fixed && 1200,

  '@media (max-width: 904px)': {
    margin: 0,
  },
}));

const SearchTextField = styled(TextField)(({ theme, fixed }) => ({
  background: theme.palette.background.secondary,
  flex: 1,
  letterSpacing: '0.25px',
  width: '100%',
  height: fixed ? 41 : 37,
  '& fieldset': {
    border: fixed ? 'none' : `1px solid ${theme.palette.border.decorative}`,
    borderRadius: 8,
  },
  '& input::placeholder': {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    opacity: 1,

    '@media (max-width: 1023px)': {
      fontSize: 13,
    },
  },
  '& input': {
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: 400,
    padding: 0,
    width: '100%',
    height: fixed ? 41 : 37,

    '@media (max-width: 1023px)': {
      fontSize: 13,
    },
    '@media (max-width: 904px)': {
      fontSize: 16,
    },
  },
  '&:has(input:focus-within) + div': {
    display: 'block',
  },

  '&:hover': {
    background: theme.palette.background.apply.hovered,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.decorative,
    },
  },
  '&:active': {
    background: theme.palette.background.apply.pressed,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.decorative,
    },
  },
}));

const Menu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '0px !important',

  '@media (max-width: 904px)': {
    display: 'none',
  },
}));

const SideNavigationButton = styled(IconButton)(() => ({
  display: 'none',

  '@media (max-width: 904px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
    padding: 0,
  },
}));

const IconContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0.5),
  padding: '0 !important',

  '& svg': {
    marginBottom: -5,
  },
  '&:hover': {
    background: theme.palette.background.apply.hovered,
    borderRadius: '50%',
  },
  '&:active': {
    background: theme.palette.background.apply.pressed,
    borderRadius: '50%',
  },
}));

const CustomMenuButton = styled(MenuButton)(({ theme, active }) => ({
  border: active === 'true' ? `3px solid ${theme.palette.link.primary}` : 'none',
  padding: theme.spacing(0.5, 1),
  height: 'auto',
  color: active === 'true' ? theme.palette.link.primary : theme.palette.text.main,
}));

/********************  Main Component  ********************/
const Header = ({ toggleTheme }) => {
  const theme = useTheme();
  const matchNavSideMenu = useMediaQuery('(max-width: 1280px)');
  const below905 = useMediaQuery('(max-width: 904px)');

  const location = useLocation();
  const history = useHistory();
  const { fixedTopPosition } = useTopValue();

  const [mobileNavOpen, setMobileNavOpen] = useStateIfMounted(false);
  const [walletSidebarOpen, setWalletSidebarOpen] = useStateIfMounted(false);
  const [settingMenu, setSettingMenu] = useStateIfMounted(false);
  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const [error, setError] = useStateIfMounted(false);
  const [searchText, setSearchText] = useStateIfMounted('');
  const [searchResult, setSearchResult] = useStateIfMounted({});

  const { pathname, search } = location;

  const handleSearch = (input) => {
    setSearchText(input);
    setIsLoading(true);
    setError(false);
    searchService
      .search(input)
      .then((response) => {
        setSearchResult(response);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setIsLoading(false);
      });
  };

  const debouncedChangeHandler = debounce(handleSearch, 500);

  const handleCloseSideModals = () => {
    setWalletSidebarOpen(false);
    if (settingMenu) {
      setMobileNavOpen(true);
    }
    if (!walletSidebarOpen) {
      setMobileNavOpen(!mobileNavOpen);
      setSettingMenu(false);
    }
  };

  const goPath = (path) => {
    const target = path.includes('launchpad') ? false : true;
    path.startsWith('https') ? openLink(path, target) : history.push(path);
  };

  const isActiveSubMenu = (submenus) => {
    if (pathname === '/') {
      return false;
    }

    return submenus.some((submenu) => {
      if (Array.isArray(submenu.tabs)) {
        return submenu.tabs.some((tab) => tab === pathname + search);
      }
      return false;
    });
  };

  const isActiveTab = (tabs) => {
    const currentUrl = pathname + search;
    return tabs.some((tab) => currentUrl.startsWith(tab));
  };

  const showSearchResultContainer = useMemo(
    () => searchText && searchText.length > 2,
    [searchText],
  );

  useEffect(() => {
    if (pathname.includes('account/settings')) {
      setSettingMenu(true);
    }
  }, []);

  return (
    <>
      <AccountSettingsSidebar
        setWalletSidebarOpen={setWalletSidebarOpen}
        open={matchNavSideMenu && settingMenu}
        setSettingMenuOpen={(val) => {
          setSettingMenu(val);
        }}
      />
      <SideNavigation
        open={matchNavSideMenu && mobileNavOpen}
        menus={NAV_MENUS}
        setMobileNavOpen={(val) => {
          setMobileNavOpen(val);
        }}
        toggleTheme={toggleTheme}
      />
      <WalletSidebar
        open={walletSidebarOpen}
        setWalletSidebarOpen={setWalletSidebarOpen}
        handleSettings={() => {
          setWalletSidebarOpen(false);
          setSettingMenu(true);
          setMobileNavOpen(false);
        }}
      />

      <Root top={fixedTopPosition}>
        <Container path={pathname.includes('/marketplace') ? 1 : 0}>
          <GMILogo logoColor={theme.palette.text.main} />
          <Menu>
            {NAVBAR_MENUS.map((item, index) =>
              item.submenus ? (
                <SubMenu
                  key={index}
                  index={index}
                  name={item.text}
                  path={item.path}
                  menus={item.submenus}
                  active={isActiveSubMenu(item.submenus)}
                />
              ) : (
                <CustomMenuButton
                  key={index}
                  onClick={() => goPath(item.path)}
                  active={(item.tabs
                    ? isActiveTab(item.tabs)
                    : pathname + search === item.path
                  ).toString()}
                  className={
                    item.text === 'Airdrop' ? 'airdrop' : item.text === 'Launchpad' && 'launchpad'
                  }
                >
                  {item.text}
                </CustomMenuButton>
              ),
            )}
          </Menu>
          {!below905 && (
            <SearchFieldContainer>
              <SearchTextField
                placeholder="Search"
                icon={SearchIcon}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ color: theme.palette.icon.tertiary }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  debouncedChangeHandler(e.target.value);
                }}
              />
              {showSearchResultContainer && (
                <SearchResultContainer
                  isLoading={isLoading}
                  searchResult={searchResult}
                  setSearchText={setSearchText}
                  onClose={() => {
                    setSearchText('');
                    setSearchResult([]);
                  }}
                  isError={error}
                />
              )}
            </SearchFieldContainer>
          )}

          {!below905 &&
            (theme.palette.mode === 'dark' ? (
              <ToolTip title="Switch to Light theme" placement="top-end">
                <IconContainer onClick={toggleTheme}>
                  <LightIcon />
                </IconContainer>
              </ToolTip>
            ) : (
              <ToolTip title="Switch to Dark theme" placement="top-end">
                <IconContainer onClick={toggleTheme}>
                  <DarkIcon />
                </IconContainer>
              </ToolTip>
            ))}

          <AddressButton openWalletSideBar={() => setWalletSidebarOpen(!walletSidebarOpen)} />

          <SideNavigationButton onClick={handleCloseSideModals}>
            {!mobileNavOpen && !walletSidebarOpen ? <MenuIcon /> : <CloseIcon />}
          </SideNavigationButton>
        </Container>
      </Root>
      {below905 && (
        <SearchFieldContainer fixed="true" top={fixedTopPosition}>
          <SearchTextField
            placeholder="Search"
            icon={SearchIcon}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ color: theme.palette.icon.tertiary, paddingLeft: '8px' }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              debouncedChangeHandler(e.target.value);
            }}
            fixed="true"
          />
          {showSearchResultContainer && (
            <SearchResultContainer
              isLoading={isLoading}
              searchResult={searchResult}
              setSearchText={setSearchText}
              onClose={() => {
                setSearchText('');
                setSearchResult([]);
              }}
              isError={error}
            />
          )}
        </SearchFieldContainer>
      )}
    </>
  );
};

export default Header;
