import React, { Fragment, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Drawer, Box, List, ListItemText, ListItemButton, styled, useTheme } from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { RESOURCE_MENU, /*REWARDS_MENU,*/ SOCIAL_MEDIAS } from 'constants/index';
import useTopValue from 'hooks/useTopValue';
import { openLink } from 'utils/openLink';

import { DarkIcon, LightIcon } from 'components/ImageComponent';
import { MenuItemArrowIcon, NavigationBody } from 'components/StyledComponents';

/********************  Styled Components  ********************/
const Footer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: 51,
  backgroundColor: theme.palette.background.default,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
  bottom: 0,

  a: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledNavBody = styled(NavigationBody)(({ top }) => ({
  width: 232,
  marginTop: top - 41, // subtract 41 (height of search bar) because this is a drawer

  // override the default drawer style
  '@media (max-width: 600px)': {
    width: 232,
  },
}));

const StyledFilterMenu = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  height: 56,
  borderBottom: `0.5px solid ${theme.palette.border.decorative}`,
}));

/********************  Main Component  ********************/
const SideNavigation = ({ toggleTheme, menus, open, setMobileNavOpen }) => {
  const theme = useTheme();

  const { pathname } = useLocation();
  const history = useHistory();
  const { marginTop } = useTopValue();

  const [sideNav, setSideNav] = useStateIfMounted([]);

  const menuList = {
    /* TODO: Unhide when farming or listing is ready
    Rewards: REWARDS_MENU,*/
    Resources: RESOURCE_MENU,
  };

  useEffect(() => {
    setMobileNavOpen(false);
    setSideNav([]);
  }, [pathname]);

  useEffect(() => {
    if (!open) {
      setSideNav([]);
    }
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        zIndex: 1280,
        '& .MuiDrawer-paper': {
          background: theme.palette.background.default,
        },
      }}
    >
      <StyledNavBody top={marginTop}>
        <Box>
          <List sx={{ py: 0 }}>
            {menus.map(
              (menu, idx) =>
                !menu?.notShowInSideNav && (
                  <Fragment key={idx}>
                    <StyledFilterMenu
                      onClick={() =>
                        menu.submenu &&
                        setSideNav((sideNav) =>
                          sideNav.includes(menu.text)
                            ? sideNav.filter((item) => item !== menu.text)
                            : [...sideNav, menu.text],
                        )
                      }
                      component={Box}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: pathname === menu.path.split('?')[0] && theme.palette.link.primary,
                        }}
                        primary={menu.text}
                        onClick={() => {
                          menu.path.startsWith('https')
                            ? openLink(menu.path, menu.path.includes('launchpad') ? false : true)
                            : history.push(menu.path);
                          menu.text !== 'Resources' && setMobileNavOpen(false);
                        }}
                        // ternary operator to avoid console error
                        className={
                          menu.text === 'Airdrop'
                            ? 'airdrop'
                            : menu.text === 'Launchpad'
                            ? 'launchpad'
                            : ''
                        }
                      />
                      {menu.submenu &&
                        (sideNav.includes(menu.text) ? (
                          <MenuItemArrowIcon>
                            <ExpandLessIcon />
                          </MenuItemArrowIcon>
                        ) : (
                          <MenuItemArrowIcon>
                            <ExpandMoreIcon />
                          </MenuItemArrowIcon>
                        ))}
                    </StyledFilterMenu>

                    {sideNav.includes(menu.text) &&
                      menuList[menu.text]?.map((item, index) => (
                        <StyledFilterMenu key={index}>
                          <ListItemText
                            sx={{
                              marginLeft: 2,
                            }}
                            primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }}
                            primary={item.text}
                            onClick={() => {
                              item.path.startsWith('https')
                                ? openLink(item.path)
                                : history.push(item.path);
                              setMobileNavOpen(false);
                            }}
                            // ternary operator to avoid console error
                            className={item.text === 'Rewards' ? 'rewards-menu' : ''}
                          />
                        </StyledFilterMenu>
                      ))}
                  </Fragment>
                ),
            )}
            <StyledFilterMenu onClick={toggleTheme} key="theme">
              <ListItemText
                primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }}
                primary="Theme"
              />
              <MenuItemArrowIcon>
                {theme.palette.mode === 'dark' ? <LightIcon /> : <DarkIcon />}
              </MenuItemArrowIcon>
            </StyledFilterMenu>
          </List>
        </Box>

        <Footer>
          {SOCIAL_MEDIAS.map((media) => (
            <a key={media.name} rel="noopener noreferrer" target="_blank" href={media.link}>
              {media.icon}
            </a>
          ))}
        </Footer>
      </StyledNavBody>
    </Drawer>
  );
};

export default SideNavigation;
