import { OrderbookApi } from 'clientSDK';

const orderbookApi = new OrderbookApi();
export default {
  create: (body) => orderbookApi.create(body),

  cancelOrder: (body) => orderbookApi.cancelOrder(body),

  getOffersMade: ({ makerAddress }) => orderbookApi.getOffersMade({ makerAddress }),

  getOrder: (hashKey) => orderbookApi.getOrder(hashKey),

  getOrders: (hashKeys) => orderbookApi.getOrders(hashKeys),

  createBidOrders: (body) => orderbookApi.createBidOrders(body),
};
