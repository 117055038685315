import { ApiClient } from '../ApiClient';

export class UserApi {
  /**
   * Constructs a new UserApi.
   * @alias module:api/UserApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.userApiInstance;
  }

  /**
   * Get user data from address
   *
   * @param {string} address
   */
  async getUser(address) {
    return await this.apiClient.callApi('/user/{address}', 'get', { address }, {}, null);
  }

  /**
   * Update user data
   *
   * @param {{*}} userData The user date to update to server
   */
  async updateUser(userData) {
    return await this.apiClient.callApi('/user/update', 'post', {}, {}, null, undefined, userData);
  }

  /**
   * Get nonce preparing for authentication
   *
   * @param {string} address
   */
  async getNonce(address) {
    return await this.apiClient.callApi('/user/nonce/{address}', 'get', { address }, {}, null);
  }

  /**
   * Authenticate user
   *
   * @param {{address, signature}} body User address is stored here
   */
  async authenticate(body) {
    return await this.apiClient.callApi('/user/authenticate', 'post', {}, {}, null, {}, body);
  }

  /**
   * Log user out
   *
   */
  async logout() {
    return await this.apiClient.callApi('/user/logout', 'post', {}, {}, null);
  }

  /**
   * Returns all favorite nfts for user
   * @param {string} address
   */
  async getFavoriteNfts(address) {
    return await this.apiClient.callApi('/favorite', 'get', {}, { address }, null);
  }

  /**
   * Returns all favorite nfts for user
   * @param {string} address
   */
  async getFavoriteCollections(address) {
    return await this.apiClient.callApi('/favoriteCollections', 'get', {}, { address }, null);
  }

  /**
   * Add new favorite nft for current logged user
   * @param {{tokenAddress, tokenId}} body
   */
  async favorite(body) {
    return await this.apiClient.callApi('/favorite', 'post', {}, {}, null, undefined, body);
  }

  /**
   * Remove a favorite nft for current logged user
   * @param {{tokenAddress, tokenId}} body
   */
  async unfavorite(body) {
    return await this.apiClient.callApi('/favorite', 'delete', {}, {}, null, undefined, body);
  }

  /**
   * Get all received offers from user
   * @param {String} address Address from user
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   */
  async getReceivedOffers({ address, skip, limit }) {
    return await this.apiClient.callApi('/user/offers', 'get', {}, { address, skip, limit }, null);
  }

  /**
   * Lock account
   */
  async lockAccount() {
    return await this.apiClient.callApi('/user/lock', 'post', {}, {}, null);
  }

  async updateCollectionImage({ data }) {
    return await this.apiClient.callApi(
      '/collection/updateimage',
      'post',
      {},
      {},
      null,
      undefined,
      data,
    );
  }

  async getPortfolioStats({ collection, userAddress }) {
    return await this.apiClient.callApi(
      '/user/portfolioStats',
      'get',
      {},
      { collection, userAddress },
      null,
    );
  }

  async applyReferral(body) {
    return await this.apiClient.callApi('/user/apply-referral', 'post', {}, {}, null, {}, body);
  }

  async getReferralReward() {
    return await this.apiClient.callApi('/user/referral-reward', 'get', {}, {}, null);
  }

  async getOwnedBids(address) {
    return await this.apiClient.callApi(
      '/collection/ownedBids/{address}',
      'get',
      { address },
      {},
      null,
    );
  }
}
