import { ApiClient } from '../ApiClient';

export class CollectionsApi {
  /**
   * Constructs a new CollectionsApi.
   * @alias module:api/CollectionsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * List details of a specific collection
   * @param {String} contractAddress Contract address of the collection
   */
  getCollectionFromAddress({ contractAddress }) {
    return this.apiClient.callApi(
      '/collections/{contractAddress}',
      'get',
      { contractAddress },
      {},
      null,
    );
  }

  /**
   * List of user owned collections
   * @param {String} address User wallet address
   */
  getCollectionByOwner({ address }) {
    return this.apiClient.callApi('/collections/owner/{address}', 'get', { address }, {}, null);
  }

  getPortfolio(ownerAddress) {
    return this.apiClient.callApi(
      '/collections/portfolio/{ownerAddress}',
      'get',
      { ownerAddress },
      {},
      null,
    );
  }

  getCollectionBidsFromAddress({ address }) {
    return this.apiClient.callApi('/collections/bids/{address}', 'get', { address }, {}, null);
  }

  getCreatorCollection(collectionUrl) {
    return this.apiClient.callApi(
      '/collections/creator/{collectionUrl}',
      'get',
      { collectionUrl },
      {},
      null,
    );
  }

  getCollectionChart(address) {
    return this.apiClient.callApi('/collections/chart/{address}', 'get', { address }, {}, null);
  }

  cancelCollectionBid(orderKey) {
    return this.apiClient.callApi(
      '/collections/cancelBid/{orderKey}',
      'post',
      { orderKey },
      {},
      null,
    );
  }
}
